<template>
  <div class="md:px-12 md:pt-10 mx-auto" v-if="experience">
    <MobileHeader
      :headingText="getHeaderText()"
      routeName="OpenDemosCatalog"
      class="block md:hidden"
    />
    <Heading
      :headingText="getHeaderText()"
      routeName="OpenDemosCatalog"
      class="hidden md:block"
    />
    <div
      class="exp-container bg-nColorBlue flex justify-center items-center relative mt-16"
    >
      <div id="winwheel-wrapper">
        <VueWinwheel
          :segments="options"
          :prizeNumberProp="endWheel"
          :outerRadiusProp="outerRadius"
          @showPrize="openStwModal"
        />
      </div>
      <div
        v-if="choiceModal.status"
        class="absolute top-0 left-0 inset-0 overflow-y-none"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          class="flex items-start justify-center px-4 pb-0 text-center sm:block sm:p-0"
        >
          <div
            class="absolute top-0 left-0 inset-0 h-full bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>

          <!-- This element is to trick the browser into centering the modal contents. -->
          <span
            class="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
            >&#8203;</span
          >

          <div
            class="absolute top-1/2 w-2/3 left-1/2 -translate-x-1/2 -translate-y-1/2 inline-block items-center rounded-lg overflow-hidden shadow-xl transform transition-all justify-center z-50"
          >
            <div class="bg-nColorBlue pb-4 sm:p-6 sm:pb-4">
              <div class="">
                <div class="mt-3 pt-8 md:pt-0 text-center">
                  <h3
                    class="text-3xl md:text-6xl text-white font-semibold"
                    id="modal-title"
                  >
                    {{ choiceModal.text }}
                  </h3>
                </div>
              </div>
            </div>
            <div class="bg-nColorBlue px-4 py-3 pb-6 text-center">
              <button
                @click.prevent="closeModal"
                type="button"
                class="w-11/12 inline-flex justify-center rounded-md border border-transparent shadow-sm px-10 uppercase py-2 bg-nButtonGreen text-base font-medium text-white"
              >
                Done
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import VueWinwheel from "vue-winwheel/vue-winwheel";
import sliceText from "@/common/text.multiline";
import obkSlugs from "@/common/slugs";
import MobileHeader from "@/components/Demo/MobileHeader.vue";
import Heading from "@/components/Demo/Heading.vue";
import Footer from "@/components/Demo/CatalogElements/DesktopFooter.vue";

export default {
  name: "SpinTheWheel",
  props: ["id"],
  created() {
    ApiService.get(apiResource.demo.getDetails, {
      slug: obkSlugs.communication.spinthewheel,
    })
      .then(({ data }) => {
        this.experience = data.data;
        this.slice1 = data.data.exp_details.slice1;
        this.slice2 = data.data.exp_details.slice2;
        this.slice3 = data.data.exp_details.slice3;
        this.slice4 = data.data.exp_details.slice4;
        this.url = data.data.exp_details.url;
        this.endWheel = parseInt(
          data.data.exp_details.stops_at.split("").pop()
        );
        this.validSource = true;
      })
      .catch(() => {});
  },
  data() {
    return {
      experience: null,
      endWheel: null,
      slice1: null,
      slice2: null,
      slice3: null,
      slice4: null,
      url: null,
      choiceModal: {
        status: null,
        text: null,
      },
      errorMsg: null,
      validSource: false,
      outerRadius: 153,
    };
  },
  components: { VueWinwheel, MobileHeader, Heading, Footer },
  computed: {
    options() {
      return [
        {
          textFillStyle: "#fff",
          fillStyle: "#FBB200",
          text: sliceText(this.slice1),
          textFontWeight: "normal",
        },
        {
          textFillStyle: "#000",
          fillStyle: "#11BCA2",
          text: sliceText(this.slice2),
          textFontWeight: "normal",
        },
        {
          textFillStyle: "#fff",
          fillStyle: "#E74D65",
          text: sliceText(this.slice3),
          textFontWeight: "normal",
          textDirection: "reversed",
        },
        {
          textFillStyle: "#000",
          fillStyle: "#F0F0F0",
          text: sliceText(this.slice4),
          textFontWeight: "normal",
          textDirection: "reversed",
        },
      ];
    },
  },
  methods: {
    openStwModal(prize) {
      document.getElementById("winwheel-wrapper").classList.remove("show");
      document.getElementById("winwheel-wrapper").classList.add("hide");
      this.choiceModal.status = true;
      this.choiceModal.text = prize;
    },
    closeModal() {
      this.choiceModal.status = false;
      document.getElementById("winwheel-wrapper").classList.remove("hide");
      document.getElementById("winwheel-wrapper").classList.add("show");
      if (this.url) {
        window.open(this.url, "_blank");
      }
    },
    getHeaderText() {
      return this.experience.name;
    },
  },
};
</script>

<style scoped>
.hide {
  display: none;
}
.show {
  display: block;
}
.exp-container {
  height: 34rem;
}
</style>